label.text_input_label{
    font-size:16px;
    font-family: var(--primary-normal-font);
    color: var(--quinary-text-color); 
    padding: 0 10px;
    display:flex;
    width:100%;
    justify-content: space-between;
    padding:6px
}
label.checkbox_input_label{
    font-size:16px;
    font-family: var(--primary-normal-font);
    color: var(--quinary-text-color); 
    padding: 0 10px;
    display:flex;
    width:100%;
    justify-content: flex-start;
    padding:6px
}


.user_pop_up_input{
    width:75%;
    height:26px;
    padding:4px;
    font-size:12px;
}

.checkbox_container{
    display:flex;
    flex-direction: column;
    margin-left:12px;
}

