.root {
    display: 'flex';
    flex-wrap: 'nowrap';
    justify-content: 'space-around';
    background-color: white;
    
  }
  
  .gridList {
    flex-wrap: wrap;
    overflow: hidden;
    
  }
  .input {
    display: none;
    
  }