:root {
  --primary-text-color: #292929;
  --primary-line-color: #292929;
  --primary-menu-color: #292929;
  --primary-button-color: #292929;
  --secondary-button-color: #ffffff;
  --tertiary-button-color: #3A363D;
  --secondary-text-color: #ffffff;
  --secondary-menu-color: #ffffff;
  --tertiary-text-color: red;
  --quaternary-text-color: #4C484E;
  --quinary-text-color: #4d5869;
  --primary-border-color: #E9E9E9;

  --primary-normal-font: CircularStd-Book;
  --primary-medium-font: CircularStd-Medium;
  --primary-bold-font: CircularStd-Bold;
  --secondary-normal-font: LyonDisplay-Regular;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: LyonDisplay-Regular;
  src: url(../src/assets/fonts/LyonDisplay-Regular.otf) format('opentype');
}
@font-face {
  font-family: CircularStd-Book;
  src: url(../src/assets/fonts/CircularStd-Book.ttf) format('truetype');
}
@font-face {
  font-family: CircularStd-Medium;
  src: url(../src/assets/fonts/CircularStd-Medium.ttf) format('truetype');
}
@font-face {
  font-family: CircularStd-Bold;
  src: url(../src/assets/fonts/CircularStd-Bold.ttf) format('truetype');
}
@font-face {
  font-family: Helvetica-Bold;
  src: url(../src/assets/fonts/Helvetica-Bold.ttf) format('truetype');
}
@font-face {
  font-family: Helvetica-Regular;
  src: url(../src/assets/fonts/Helvetica.ttf) format('truetype');
}

/* @font-face {
  font-family: 'icomoon';
  src:url('../icons/icomoon.eot');
  src:url('../icons/icomoon.eot?#iefix') format('embedded-opentype'),
      url('../icons/icomoon.woff') format('woff'),
      url('../icons/icomoon.ttf') format('truetype'),
      url('../icons/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
} */
button:focus {
  outline: none;
}
.el-dialog__wrapper {
  z-index: 10005 !important;
}
.v-modal {
  z-index: 10004;
}
.is-flex-1 {
  flex: 1;
}
.is-g-regular {
  font-family: GRegular;
}

.is-g-semiBold {
  font-family: GSemiBold;
}
.is-g-light {
  font-family: GLight;
}
.is-g-medium {
  font-family: GMedium;
}
.input-label{
  display: inline-block;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  text-align: left;
  background-color: white;
  font-size:12px ;
}
.ibt {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 0;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.vertical-flex-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  flex-direction: column;
}
.horizontal-flex-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.half-flex-item {
  flex: auto;
}
.full-flex-item {
  flex: auto;
  min-width: 100%;
}
.primary-text-color {
  color: var(--primary-text-color) !important;
}
.secondary-text-color {
  color: var(--secondary-text-color) !important;
}
.warning-text-color {
  color: var(--tertiary-text-color) !important;
}
.quaternary-text-color {
  color: var(--quaternary-text-color) !important;
}
.quinary-text-color {
  color: var(--quinary-text-color) !important;
}
.primary-line-color {
  color: var(--primary-line-color);
}
.primary-button-background-color {
  background-color: var(--primary-button-color) !important;
}
.secondary-button-background-color {
  background-color: var(--secondary-button-color) !important;
}
.avatar-bg-color {
  background-color: var(--primary-menu-color);
}
.text-decoration-none {
  text-decoration: none;
}
.overflow-visible {
  overflow: visible !important;
}
.z-index-1 {
  z-index: 1;
}
.width-40 {
  width: 40% !important;
}
.width-50 {
  width: 50% !important;
}
.width-75 {
  width: 75% !important;
}
.width-100 {
  width: 100% !important;
}
.width-initial {
  width: initial !important;
}
.width-20px {
  width: 20px !important;
}
.width-50px {
  width: 50px !important;
}
.width-80px {
  width: 80px !important;
}
.width-480px {
  width: 480px !important;
}
.width-650px {
  width: 650px !important;
}
.max-width-200px {
  max-width: 200px;
}
.max-width-500px {
  max-width: 500px !important;
}
.height-100 {
  height: 100% !important;
}
.height-100vh {
  height: 100vh !important;
}
.height-50px {
  height: 50px !important;
}
.height-80px {
  height: 80px !important;
}
.height-220px {
  height: 220px !important;
}
.height-300px {
  height: 300px;
}
.max-height-100px {
  max-height: 100px;
}
.min-height-32px {
  min-height: 32px;
}
.position-fixed {
  position: fixed !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.bottom-50 {
  bottom: 50% !important;
}
.right-50 {
  right: 50% !important;
}
.align-self-flex-end {
  align-self: flex-end;
}
.align-self-center {
  align-self: center !important;
}
.align-items-center {
  align-items: center !important;
}
.text-align-center {
  text-align: center;
}
.text-align-right {
  text-align: right !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-right {
  justify-content: right !important;
}
.justify-content-space-between {
  justify-content: space-between !important;
}
.float-right {
  float: right !important;
}
.padding-24px {
  padding: 24px;
}
.padding-top-5px {
  padding-top: 5px;
}
.padding-top-12px {
  padding-top: 12px;
}
.padding-top-15px {
  padding-top: 15px;
}
.padding-top-20px {
  padding-top: 20px;
}
.padding-top-24px {
  padding-top: 24px !important;
}
.padding-top-30px {
  padding-top: 30px;
}
.padding-bottom-30px {
  padding-bottom: 30px;
}
.padding-bottom-40px {
  padding-bottom: 40px;
}
.padding-left-50px {
  padding-left: 50px;
}
.padding-top-bottom-8px {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.padding-top-bottom-24px {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}
.padding-left-right-16px {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.padding-right-left-24px {
  padding-right: 24px !important;
  padding-left: 24px !important;
}
.padding-left-right-32px {
  padding-left: 32px !important;
  padding-right: 32px !important;
}
.margin-auto {
  margin: auto !important;
}
.margin-right-auto {
  margin-right: auto !important;
}
.margin-12px {
  margin: 12px !important;
}
.margin-40px {
  margin: 40px !important;
}
.margin-top-0 {
  margin-top: 0 !important;
}
.margin-top-10px {
  margin-top: 10px !important;
}
.margin-top-15px {
  margin-top: 15px !important;
}
.margin-top-20px {
  margin-top: 20px !important;
}
.margin-top-24px {
  margin-top: 24px !important;
}
.margin-top-30px {
  margin-top: 30px !important;
}
.margin-top-45px {
  margin-top: 45px !important;
}
.margin-bottom-5px {
  margin-bottom: 5px !important;
}
.margin-bottom-8px {
  margin-bottom: 8px !important;
}
.margin-bottom-20px {
  margin-bottom: 20px !important;
}
.margin-top-bottom-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.margin-top-bottom-16px {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}
.margin-top-bottom-0px {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.margin-top-bottom-40px {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.margin-top-bottom-48px {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}
.margin-left-6px {
  margin-left: 6px !important;
}
.margin-left-24px {
  margin-left: 24px !important;
}
.margin-left-32px {
  margin-left: 32px !important;
}
.margin-left-36px {
  margin-left: 36px;
}
.margin-left-40px {
  margin-left: 40px;
}
.margin-right-10px {
  margin-right: 10px !important;
}
.margin-right-208px {
  margin-right: 208px !important;
}
.margin-left-right-0px {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.margin-left-right-4px {
  margin-left: 4px !important;
  margin-right: 4px !important;
}
.margin-left-right-5px {
  margin-left: 5px !important;
  margin-right: 5px !important;
}
.margin-left-right-32px {
  margin-left: 32px !important;
  margin-right: 32px !important;
}
.margin-left-right-36px {
  margin-left: 36px !important;
  margin-right: 36px !important;
}
.margin-left-right-40px {
  margin-left: 40px !important;
  margin-right: 40px !important;
}
.margin-right-bottom-left-30px {
  margin-right: 30px !important;
  margin-bottom: 30px !important;
  margin-left: 30px !important;
}
.border-none {
  border: none;
}
.border-bottom-width-1px {
  border-bottom-width: 1px;
}
.border-bottom-style-solid {
  border-bottom-style: solid;
}
.border-right-width-1px {
  border-right-width: 1px;
}
.border-right-style-solid {
  border-right-style: solid;
}
.border-radius-0px {
  border-radius: 0px !important;
}
.border-radius-12px {
  border-radius: 12px !important;
}
.display-none {
  display: none !important;
}
.display-flex {
  display: flex !important;
}
.flex-direction-row {
  flex-direction: row !important;
}
.flex-direction-column {
  flex-direction: column !important;
}
.flex-wrap {
  flex-wrap: wrap;
}
.primary-normal-font {
  font-family: var(--primary-normal-font) !important;
}
.primary-medium-font {
  font-family: var(--primary-medium-font) !important;
}
.primary-bold-font {
  font-family: var(--primary-bold-font) !important;
}
.secondary-normal-font {
  font-family: var(--secondary-normal-font) !important;
  font-variant-numeric: lining-nums;
}
.font-weight-bold {
  font-weight: bold;
}
.font-weight-450 {
  font-weight: 450;
}
.line-height-text-area {
  line-height: 1.5;
}
.line-height-15px {
  line-height: 15px;
}
.line-height-43px {
  line-height: 43px;
}
.background-color-text-area {
  background-color: #fafafa;
}
.background-color-white {
  background-color: white;
}
.font-size-8 {
  font-size: 8px !important;
}
.font-size-11 {
  font-size: 11px !important;
}
.font-size-12 {
  font-size: 12px !important;
}
.font-size-14 {
  font-size: 14px !important;
}
.font-size-15 {
  font-size: 15px !important;
}
.font-size-18 {
  font-size: 18px !important;
}
.font-size-24 {
  font-size: 24px !important;
}
.font-size-26 {
  font-size: 26px !important;
}
.font-size-28 {
  font-size: 28px !important;
}
.font-size-30 {
  font-size: 30px;
}
.font-size-36 {
  font-size: 36px;
}
.font-size-56 {
  font-size: 56px !important;
}
.letter-spacing-0-5 {
  letter-spacing: 0.5px !important;
}
.letter-spacing-0-11em {
  letter-spacing: 0.11em;  
}
.text-transform-uppercase {
  text-transform: uppercase !important;
}
.text-transform-capitalize {
  text-transform: capitalize !important;
}
.opacity-10 {
  opacity: 0.1 !important;
}
.opacity-20 {
  opacity: 0.2 !important;
}
.opacity-50 {
  opacity: 0.5 !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.box-shadow-none {
  box-shadow: none !important;
}
.profile-icon img {
  object-fit: contain;
  width: 50%;
  height: 50%;
}
.receipt-icon img {
  object-fit: contain;
  width: 60%;
  height: 60%;
}

.old-mp-spacing {
  margin: 64px 75px 0px 75px;
  padding: 24px;
}

.expanded-content {
  height: 0;
  overflow: hidden;
  transition: height 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.expanded-content.open {
  height: auto;
}

.click-disabled {
  pointer-events: none;
}

.smooth-click:not(.click-disabled) {
  transition: 0.2s all;

  &:active {
    transform: scale(0.95);
  }
}

/*  MOBILE SCREEN */
@media only screen and (max-width: 668px) {
  .hidden-mobile {
    display: none !important;
  }
  .desktop-only {
    display: none !important;
  }
  .tablet-only {
    display: none !important;
  }
}
/* TABLET SCREEN */
@media only screen and (max-width: 1024px) and (min-width : 667px) {
  .hidden-tablet {
    display: none !important;
  }
  .desktop-only {
    display: none !important;
  }
  .mobile-only {
    display: none !important;
  }
}
/* DESKTOP SCREEN */
@media only screen and (min-width: 1024px) {
  .hidden-desktop {
    display: none !important;
  }
  .mobile-only {
    display: none !important;
  }
  .tablet-only {
    display: none !important;
  }
}
