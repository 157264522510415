.home-page-menu-bar{
    display:flex;
    justify-content: space-between;
}

.borderVertical{
    
   width:10px;
   
}

.gridFirstRow{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-self: center;
    text-align: center;
    padding-top: 30px;
}
.gridCell{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center !important;
    width:80%;
}
.insideBox{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width:80%;
    height:65%;
}
.gridSecondRow{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-self: center;
    align-items: center;
    text-align: center;
   
   
    
    

}
.Title{
    justify-content: center !important;
    align-items: center;
    text-align: center !important;
   
    
   
    

    
}