.home-page-section {
    background-color: white;
}

.home-page-menu-bar {
    display: flex;
    justify-content: space-between;
    padding-top: 27px;
    padding-bottom: 22px;
    padding-left: 40px;
    /* min-height: 140px; */
    border-radius: 0px 0px 0px 32px;
    background: #F3F3F3;
    background: linear-gradient(100.59deg, rgba(240, 240, 240, 0.4) 0%, rgba(211, 211, 211, 0.4) 100%), #FFFFFF;
}

.home-page-menu-bar.floating {
    margin-left: 12px;
    margin-right: 12px;
    box-shadow: 0px 4px 12px 2px #0000004D;
    border-radius: 999px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.custom-gradient {
    background:
        linear-gradient(180deg, rgba(255, 255, 255, 0.5) 8.92%, rgba(233, 233, 233, 0.5) 100%),
        linear-gradient(270deg, rgb(232, 216, 164) 0%, rgba(255, 255, 255, 0) 100%);

}

.borderVertical {
    width: 10px;
}

.gridFirstRow {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-self: center;
    text-align: center;
    padding-top: 30px;
}

.gridCell {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center !important;
    width: 80%;
}

.insideBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    height: 65%;
}

.gridSecondRow {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-self: center;
    align-items: center;
    text-align: center;
}

.Title {
    justify-content: center !important;
    align-items: center;
    text-align: center !important;
}

.date-range-btn {
    font-family: 'CircularStd-Book' !important;
    font-weight: 700 !important;
    line-height: 14px !important;
    border-radius: 100px !important;
    padding: 11px 18px !important;
    margin-bottom: 2px !important;
    border: 1px solid #0000001A !important;
    background: #FFFFFF !important;
    text-transform: none !important;
}

.date-range-btn.selected {
    border: 1px solid #F2BD0E !important;
    background: #F2BD0E !important;
    box-shadow: 0px 2px 10px 1px #0000004D;
}

.business-insights-card {
    background: white !important;
    border-radius: 12px;
}

.business-insights-card .description-text {
    margin-right: 44px !important;
}

.circular-status-bar-text {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.partners-section {
    margin-left: 40px;
    margin-right: 40px;
}

.partner-section-heading {
    margin-top: 40px;
    margin-bottom: 40px;
    font-family: 'CircularStd-Bold';
    font-size: 15px;
    line-height: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    color: #000000;
}

.partners-container {
    display: flex;
    overflow: hidden;
    position: relative;
}

.scrollable-partners {
    display: flex;
    overflow-x: scroll;
    scroll-behavior: smooth;
    gap: 20px;
    width: 100%;
}

.invisible-scrollbar {
    scrollbar-width: none;
}
.invisible-scrollbar::-webkit-scrollbar {
    display: none;
}

.partner-card {
    flex: 0 0 auto;
    /* height: 351px; */
    background-color: #f9f9f9;
    border-radius: 10px;
    padding: 20px 2% 50px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 80px;
    box-sizing: border-box;
}

.partner-logo {
    margin-top: 15px;
    width: 160px;
    height: 66px;
    object-fit: contain;
}

.partner-card p {
    font-size: 11px;
    margin-left: 10px;
    font-family: Helvetica-Bold;
    text-align: left;
    margin-top: 30px;
}

.card-details {
    text-align: left;
    padding: 0 20px;
}

.card-details li {
    margin-bottom: 8px;
    font-size: 11px;
    font-family: Helvetica;
    line-height: 1.5;
    color: #333;
}

.scroll-right-arrow {
    position: relative;
    margin-left: -20px;
    margin-top: -75px;
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
}

.scroll-right-arrow .right-arrow-icon {
    box-shadow: 0px 1.81818px 5.45455px rgba(0, 0, 0, 0.2);
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

@media (min-width: 2001px) {
    .partner-card {
        width: calc((100% - 60px) / 4);
        padding-top: 40px;
    }

    .partner-card p {
        font-size: 18px;
    }

    .partner-card .card-details li {
        font-size: 18px;
    }
}

@media (max-width: 2000px) {
    .partner-card {
        width: calc((100% - 60px) / 4);
        padding-top: 40px;
    }

    .partner-card p {
        font-size: 15px;
    }

    .partner-card .card-details li {
        font-size: 15px;
    }
}

@media (max-width: 1700px) {
    .partner-card {
        padding-bottom: 15px;
    }

    .partner-card p {
        font-size: 12px;
    }

    .partner-card .card-details li {
        font-size: 12px;
    }
}

@media (max-width: 1600px) {
    .partner-card p {
        font-size: 11px;
    }

    .partner-card .card-details li {
        font-size: 11px;
    }
}

@media (max-width: 1500px) {
    .partner-card {
        width: calc((100% - 40px) / 3);
        padding-bottom: 15px;
        padding-left: 2.5%;
        padding-right: 2.5%;
    }

    .partner-card p {
        font-size: 12.5px;
    }

    .partner-card .card-details li {
        font-size: 12.5px;
    }
}

@media (max-width: 1300px) {
    .partner-card p {
        font-size: 11px;
    }

    .partner-card .card-details li {
        font-size: 11px;
    }
}

@media (max-width: 1100px) {
    .partner-card {
        width: calc((100% - 20px) / 2);
        padding-left: 5%;
        padding-right: 5%;
    }

    .partner-card p {
        font-size: 12px;
    }

    .partner-card .card-details li {
        font-size: 12px;
    }

}

@media (max-width: 780px) {
    .partner-card {
        width: 100%;
        padding-left: 8%;
        padding-right: 8%;
    }

    .partner-card p {
        font-size: 12px;
    }

    .partner-card .card-details li {
        font-size: 12px;
    }
}

.resources-section {
    position: relative;
    padding: 40px;
    background-color: #f9f9f9;
    overflow: hidden;
}

.ellipse-7 {
    position: absolute;
    width: 837px;
    height: 921px;
    left: 705px;
    top: 76px;
    background: #F3DA44;
    opacity: 0.4;
    filter: blur(100px);
    z-index: 0;
}

.ellipse-8 {
    position: absolute;
    width: 837px;
    height: 642px;
    left: 122px;
    top: 387px;
    background: #FAF087;
    opacity: 0.3;
    filter: blur(100px);
    z-index: 0;
}

.ellipse-9 {
    position: absolute;
    width: 549px;
    height: 525px;
    left: -183px;
    top: 279px;
    background: #EDA439;
    opacity: 0.3;
    filter: blur(100px);
    z-index: 0;
}

.resources-title {
    margin-top: 40px;
    margin-bottom: 40px;
    font-family: 'CircularStd-Bold';
    font-size: 15px;
    line-height: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    color: #000000;
}

.resources-container {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

@media (min-width: 1024px) {
    .resources-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

.resource-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
    background: rgba(255, 255, 255, 0.7);
    min-height: 400px;
    position: relative;
}

.resource-icon {
    margin-top: 32px;
    margin-bottom: 25px;
}

.resource-title {
    font-size: 20px;
    font-family: CircularStd-Bold;
    margin-bottom: 16px;
    margin-left: 32px;
    margin-right: 32px;
    text-align: left;
    align-self: flex-start;
}

.resource-description {
    font-size: 16px;
    font-family: CircularStd-Book;
    color: #000000B3;
    margin-bottom: 60px;
    margin-left: 32px;
    margin-right: 32px;
    text-align: left;
}

.resource-button {
    width: 100%;
    height: 60px;
    font-family: CircularStd-Bold;
    font-size: 16px;
    padding: 10px 15px;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    position: relative;
    bottom: 0;
    margin-top: auto;
}

.resource-button:hover {
    background-color: #333;
}

#monthly-sales-performance-hardcoded-legends-div {
    padding-left: 4px;
    padding-top: 8px;
}

#business-performance-data-unavailable-message-div {
    background-color: rgb(255, 233, 162);
    margin: 10px 24px 40px 24px;
    padding: 12px 0px 12px 24px;
}