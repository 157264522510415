

.filter-by-toggle-container {
    display: flex;
    border: 1px solid #0000001A;
    border-radius: 100px;
    overflow: hidden;
}

.filter-by-toggle-button {
    flex: 1;
    font-family: 'CircularStd-Book' !important;
    font-weight: 700 !important;
    line-height: 14px !important;
    padding: 11px 18px !important;
    text-align: center;
    cursor: pointer;
    background: #FFFFFF !important;
    color: #000;
    border: none;
    text-transform: none !important;
    box-shadow: none;
}

.filter-by-toggle-button.selected {
    background: #F2BD0E !important;
    color: #000;
    box-shadow: 0px 2px 10px 1px #0000004D;
}

.filter-by-toggle-description {
    font-family: 'CircularStd-Book' !important;
    line-height: 14px !important;
    color: #000;
}