button:hover {
    cursor: pointer;
  }
  .auth-form-success-wrapper .auth-flex-item {
    justify-content: center !important;
    align-items: center;
    text-align: center !important;
  }
  @media only screen and (max-width: 410px) {
    #authViewContainer {
      display: flex;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      align-items: flex-start;
      justify-content: center;
    }
    .auth-view-container {
      overflow: scroll;
      z-index: 2;
    }
    .vertical-auth-flex-container {
      padding-top: 10px;
      overflow: hidden;
      display: flex;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      width: 100%;
      align-items: center;
      justify-content: flex-start;
      text-align: center;
      flex-wrap: wrap;
      flex-direction: column;
    }
    .vertical-auth-flex-container .auth-flex-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    .vertical-auth-flex-container .auth-flex-item .auth-button {
      width: 100%;
      height: 45px;
      font-size: 0.8rem;
      letter-spacing: 1px;
      background-color: white;
      border: none;
      border-radius: 50px;
    }
    .vertical-auth-flex-container .auth-flex-item .auth-button:hover {
      cursor: pointer;
      background-color: rgba(77, 88, 106, 0.4);
      color: white;
    }
    .auth-view-background-container {
      height: 100%;
      width: 100%;
      overflow: hidden;
      position: absolute;
      z-index: 1;
    }
    .auth-view-background-container .auth-view-background {
      background-repeat: no-repeat;
      filter: blur(5px);
      background-size: 100% 100%;
      height: 101%;
      min-height: 800px;
      min-width: 1100px;
      width: 110%;
      left: -20px;
      top: -5px;
      bottom: -5px;
      z-index: 0;
      position: absolute;
      background-image: url('https://images.unsplash.com/photo-1497644083578-611b798c60f3?auto=format&fit=crop&w=1350&q=80&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D');
    }
    .auth-view-background-container .auth-view-background .auth-view-background-black-layer {
      background-color: rgba(0, 0, 0, 0.7);
      width: 100%;
      height: 100%;
    }
    .auth-logo-container {
      display: flex;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .auth-logo-container .auth-logo {
       background-image: url('../../assets/images/white.png');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 200px;
      height: 50px;
      z-index:1
    }

    .auth-logo-container .auth-logo-text {
      color: white;
      font-size: 0.9rem;
    }
    .auth-forms-wrapper {
      width: 100%;
      margin-top: 6%; 
    }
    .login-form .login-form-input-container {
      width: 100%;
      margin-top: 30px;
    }
    .login-form .login-form-input-container input {
      width: 100%;
      border: none;
      min-height: 40px;
      outline: none;
      background-color: transparent;
      color: white;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      background: none;
      font-size: 17px;
    }
    .login-form .login-form-action-container {
      justify-content: flex-start;
    }
    .login-form .login-form-action-container .v-checkbox .custom-control-indicator {
      background-color: rgba(0, 0, 0, 0) !important;
      border: 1px solid rgba(255, 255, 255, 0.3) !important;
      border-radius: 0;
      outline: none;
    }
    .login-form .login-form-action-container .v-checkbox .custom-control-input:focus {
      outline: none !important;
      border: none;
    }
    .login-form .login-form-action-container .v-checkbox .custom-control-description {
      color: rgba(255, 255, 255, 0.5);
    }
    .login-form .login-form-button-container {
      justify-content: center;
      margin-top: 10px;
    }
    .login-form .login-form-button-container .action-button {
      background: none;
      border: none;
      color: white;
      width: 100%;
      font-size: 0.95rem;
      font-family: var(--primary-medium-font)
    }
    .auth-form {
      padding: 10px 20px 10px 20px;
      display: flex;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      flex-direction: column;
      color: white;
      justify-content: center;
    }
    .auth-form .auth-flex-item {
      margin: 10px 0;
      justify-content: flex-start;
    }
    .forgot-credit-form-container {
      display: flex;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      flex-direction: column;
      color: white;
      justify-content: center;
    }
    .forgot-credit-form-container .auth-flex-item {
      margin: 10px 0;
      justify-content: flex-start;
    }
    .forgot-credit-form-container .forgot-credit-form-title {
      width: 100%;
      font-size: 0.8rem;
      text-align: left;
    }
    .forgot-credit-form-container .forgot-credit-form-p {
      width: 100%;
      margin-bottom: 30px;
      text-align: left;
    }
    .forgot-credit-form-container .forgot-credit-form-input {
      width: 100%;
    }
    .forgot-credit-form-container .forgot-credit-form-input input {
      width: 100%;
      border: none;
      min-height: 40px;
      outline: none;
      color: white;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      background: none;
    }
    .forgot-credit-form-container .forgot-credit-form-button-container {
      margin-top: 20px;
    }
    .forgot-credit-form-container .forgot-credit-form-action-container .action-button {
      width: 100%;
      background-color: rgba(0, 0, 0, 0);
      margin-bottom: 0;
      border: none;
      color: white;
      font-family: var(--primary-medium-font)
    }
  }
  @media (max-width: 668px) and (min-width: 410px) {
    #authViewContainer {
      display: flex;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      align-items: flex-start;
      justify-content: center;
    }
    .auth-view-container {
      overflow: scroll;
      position: absolute;
      z-index: 5;
    }
    .vertical-auth-flex-container {
      padding-top: 10px;
      overflow: hidden;
      display: flex;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      width: 100%;
      align-items: center;
      justify-content: flex-start;
      text-align: center;
      flex-wrap: wrap;
      flex-direction: column;
    }
    .vertical-auth-flex-container .auth-flex-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    .vertical-auth-flex-container .auth-flex-item .auth-button {
      width: 100%;
      height: 45px;
      font-size: 0.8rem;
      letter-spacing: 1px;
      background-color: white;
      border: none;
      border-radius: 50px;
    }
    .vertical-auth-flex-container .auth-flex-item .auth-button:hover {
      cursor: pointer;
      background-color: rgba(77, 88, 106, 0.4);
      color: white;
    }
    .auth-view-background-container {
      height: 100%;
      width: 100%;
      overflow: hidden;
      position: absolute;
      z-index: 1;
    }
    .auth-view-background-container .auth-view-background {
      background-repeat: no-repeat;
      filter: blur(5px);
      background-image: url("https://images.unsplash.com/photo-1497644083578-611b798c60f3?auto=format&fit=crop&w=1350&q=80&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D");
      background-size: 100% 100%;
      height: 101%;
      min-height: 800px;
      min-width: 1100px;
      width: 110%;
      left: -20px;
      top: -5px;
      bottom: -5px;
      z-index: 0;
      position: absolute;
    }
    .auth-view-background-container .auth-view-background .auth-view-background-black-layer {
      background-color: rgba(0, 0, 0, 0.7);
      width: 100%;
      height: 100%;
    }
    .auth-logo-container {
      display: flex;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .auth-logo-container .auth-logo {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 200px;
      height: 50px;
    }
    .auth-logo-container .auth-logo-text {
      color: white;
      font-size: 0.9rem;
    }
    .auth-forms-wrapper {
      width: 100%;
    }
    .login-form .login-form-input-container {
      width: 100%;
    }
    .login-form .login-form-input-container input {
      width: 100%;
      border: none;
      min-height: 40px;
      outline: none;
      color: white;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      background: none;
      font-size: 17px;
      
      
    }
  
    .login-form .login-form-action-container {
      justify-content: flex-start;
    }
    .login-form .login-form-action-container .v-checkbox .custom-control-indicator {
      background-color: rgba(0, 0, 0, 0) !important;
      border: 1px solid rgba(255, 255, 255, 0.3) !important;
      border-radius: 0;
      outline: none;
    }
    .login-form .login-form-action-container .v-checkbox .custom-control-input:focus {
      outline: none !important;
      border: none;
    }
    .login-form .login-form-action-container .v-checkbox .custom-control-description {
      color: rgba(255, 255, 255, 0.5);
    }
    .login-form .login-form-button-container {
      justify-content: center;
      margin-top: 10px;
    }
    .login-form .login-form-button-container .action-button {
      background: none;
      border: none;
      color: white;
      width: 100%;
      font-size: 0.95rem;
      font-family: var(--primary-medium-font)
    }
    .auth-form {
      padding: 10px 20px 10px 20px;
      display: flex;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      flex-direction: column;
      color: white;
      justify-content: center;
    }
    .auth-form .auth-flex-item {
      margin: 10px 0;
      justify-content: flex-start;
    }
    .forgot-credit-form-container {
      display: flex;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      flex-direction: column;
      color: white;
      justify-content: center;
    }
    .forgot-credit-form-container .auth-flex-item {
      margin: 10px 0;
      justify-content: flex-start;
    }
    .forgot-credit-form-container .forgot-credit-form-title {
      width: 100%;
      font-size: 0.8rem;
      text-align: left;
    }
    .forgot-credit-form-container .forgot-credit-form-p {
      width: 100%;
      margin-bottom: 30px;
      text-align: left;
    }
    .forgot-credit-form-container .forgot-credit-form-input {
      width: 100%;
    }
    .forgot-credit-form-container .forgot-credit-form-input input {
      width: 100%;
      border: none;
      min-height: 40px;
      outline: none;
      color: white;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      background: none;
    }
    .forgot-credit-form-container .forgot-credit-form-button-container {
      margin-top: 20px;
    }
    .forgot-credit-form-container .forgot-credit-form-action-container .action-button {
      width: 100%;
      background-color: rgba(0, 0, 0, 0);
      margin-bottom: 0;
      border: none;
      color: white;
      font-family: var(--primary-medium-font)
    }
  }
  @media only screen and (max-width: 1024px) and (min-width: 667px) {
    #authViewContainer {
      display: flex;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      align-items: flex-start;
      justify-content: center;
    }
    .auth-view-container {
      overflow: scroll;
      position: absolute;
      z-index: 2;
    }
    .vertical-auth-flex-container {
      padding-top: 50px;
      overflow: hidden;
      display: flex;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      width: 100%;
      align-items: center;
      justify-content: flex-start;
      text-align: center;
      flex-wrap: wrap;
      flex-direction: column;
    }
    .vertical-auth-flex-container .auth-flex-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    .vertical-auth-flex-container .auth-flex-item .auth-button {
      width: 100%;
      height: 45px;
      font-size: 0.8rem;
      letter-spacing: 1px;
      background-color: white;
      border: none;
      border-radius: 50px;
    }
    .vertical-auth-flex-container .auth-flex-item .auth-button:hover {
      cursor: pointer;
      background-color: rgba(77, 88, 106, 0.4);
      color: white;
    }
    .auth-view-background-container {
      height: 100%;
      min-width: 100%;
      overflow: hidden;
      position: absolute;
      z-index: 1;
    }
    .auth-view-background-container .auth-view-background {
      background-repeat: no-repeat;
      filter: blur(5px);
      background-image: url("https://images.unsplash.com/photo-1497644083578-611b798c60f3?auto=format&fit=crop&w=1350&q=80&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D");
      background-size: cover;
      height: 101%;
      min-height: 800px;
      min-width: 350px;
      width: 110%;
      left: -20px;
      top: -5px;
      bottom: -5px;
      z-index: 0;
      position: absolute;
    }
    .auth-view-background-container .auth-view-background .auth-view-background-black-layer {
      background-color: rgba(0, 0, 0, 0.4);
      width: 100%;
      height: 100%;
    }
    .auth-logo-container {
      display: flex;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .auth-logo-container .auth-logo {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 200px;
      height: 50px;
    }
    .auth-logo-container .auth-logo-text {
      color: white;
      font-size: 0.9rem;
    }
    .auth-forms-wrapper {
      background-color: rgba(40, 40, 40, 0.6);
      width: auto;
    }
    .login-form {
      padding: 50px 60px 10px 60px;
    }
    .login-form .login-form-input-container {
      width: 100%;
    }
    .login-form .login-form-input-container input {
      width: 100%;
      border: none;
      min-height: 40px;
      outline: none;
      color: white;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      background: none;
      font-size: 17px;
    }
    .login-form .login-form-action-container {
      justify-content: flex-start;
    }
    .login-form .login-form-action-container .v-checkbox .custom-control-indicator {
      background-color: rgba(0, 0, 0, 0) !important;
      border: 1px solid rgba(255, 255, 255, 0.3) !important;
      border-radius: 0;
      outline: none;
    }
    .login-form .login-form-action-container .v-checkbox .custom-control-input:focus {
      outline: none !important;
      border: none;
    }
    .login-form .login-form-action-container .v-checkbox .custom-control-description {
      color: rgba(255, 255, 255, 0.5);
    }
    .login-form .login-form-button-container {
      justify-content: center;
      margin-top: 10px;
    }
    .login-form .login-form-button-container .action-button {
      background: none;
      border: none;
      color: white;
      width: 100%;
      font-size: 0.95rem;
      font-family: var(--primary-medium-font)
    }
    .auth-form {
      min-width: 300px;
      padding: 50px 70px 40px 70px;
      display: flex;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      flex-direction: column;
      color: white;
      justify-content: center;
    }
    .auth-form .auth-flex-item {
      margin: 10px 0;
      justify-content: flex-start;
    }
    .forgot-credit-form-container {
      min-width: 300px;
      padding: 50px 60px 40px 60px;
      display: flex;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      flex-direction: column;
      color: white;
      justify-content: center;
    }
    .forgot-credit-form-container .auth-flex-item {
      margin: 10px 0;
      justify-content: flex-start;
    }
    .forgot-credit-form-container .forgot-credit-form-title {
      width: 100%;
      font-size: 0.8rem;
      text-align: left;
    }
    .forgot-credit-form-container .forgot-credit-form-p {
      width: 100%;
      margin-bottom: 30px;
      text-align: left;
    }
    .forgot-credit-form-container .forgot-credit-form-input {
      width: 100%;
    }
    .forgot-credit-form-container .forgot-credit-form-input input {
      width: 100%;
      border: none;
      min-height: 40px;
      outline: none;
      color: white;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      background: none;
    }
    .forgot-credit-form-container .forgot-credit-form-button-container {
      margin-top: 20px;
    }
    .forgot-credit-form-container .forgot-credit-form-action-container .action-button {
      width: 100%;
      background-color: rgba(0, 0, 0, 0);
      margin-bottom: 0;
      border: none;
      color: white;
      font-family: var(--primary-medium-font)
    }
  }
  @media only screen and (min-width: 1024px) {
    #authViewContainer {
      display: flex;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      align-items: flex-start;
      justify-content: center;
    }
    .auth-view-container {
      overflow: scroll;
      z-index: 2;
      position: absolute;
    }
    .vertical-auth-flex-container {
      padding-top: 50px;
      overflow: hidden;
      display: flex;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      width: 100%;
      align-items: center;
      justify-content: flex-start;
      text-align: center;
      flex-wrap: wrap;
      flex-direction: column;
    }
    .vertical-auth-flex-container .auth-flex-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      z-index: 99999;
    }
    .auth-view-background-container {
      height: 100%;
      min-width: 100%;
      overflow: hidden;
      position: absolute;
      z-index: 1;
    }
    .auth-view-background-container .auth-view-background {
      background-repeat: no-repeat;
      filter: blur(5px);
      background-image: url("https://images.unsplash.com/photo-1497644083578-611b798c60f3?auto=format&fit=crop&w=1350&q=80&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D");
      background-size: cover;
      height: 101%;
      min-height: 800px;
      width: 110%;
      left: -20px;
      top: -5px;
      bottom: -5px;
      z-index: 0;
      position: absolute;
    }
    .auth-view-background-container .auth-view-background .auth-view-background-black-layer {
      background-color: rgba(0, 0, 0, 0.4);
      width: 100%;
      height: 100%;
    }
    .auth-logo-container {
      display: flex;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .auth-logo-container .auth-logo {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 200px;
      height: 50px;
    }
    .auth-logo-container .auth-logo-text {
      color: white;
      font-size: 0.9rem;
    }
    .auth-forms-wrapper {
      background-color: rgba(40, 40, 40, 0.6);
      width: auto;
      margin-top: 6%;
    }
    .login-form {
      padding: 50px 60px 10px 60px;
    }
    .login-form .login-form-input-container {
      width: 100%;
    }
    .login-form .login-form-input-container input {
      width: 100%;
      border: none;
      min-height: 40px;
      outline: none;
      color: white;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      background: none;
      font-size: 17px;
    }
    .login-form .login-form-action-container {
      justify-content: flex-start;
    }
    .login-form .login-form-action-container .v-checkbox .custom-control-indicator {
      background-color: rgba(0, 0, 0, 0) !important;
      border: 1px solid rgba(255, 255, 255, 0.3) !important;
      border-radius: 0;
      outline: none;
    }
    .login-form .login-form-action-container .v-checkbox .custom-control-input:focus {
      outline: none !important;
      border: none;
    }
    .login-form .login-form-action-container .v-checkbox .custom-control-description {
      color: rgba(255, 255, 255, 0.5);
    }
    .login-form .login-form-button-container {
      justify-content: center;
      margin-top: 10px;
    }
    .login-form .login-form-button-container .auth-button {
      width: 100%;
      height: 50px;
      font-size: 0.8rem;
      letter-spacing: 1px;
      background-color: white;
      border: none;
      border-radius: 50px;
    }
    .login-form .login-form-button-container .auth-button:hover {
      cursor: pointer;
      background-color: rgba(77, 88, 106, 0.4);
      color: white;
    }
    .login-form .login-form-button-container .action-button {
      background: none;
      border: none;
      color: white;
      width: 100%;
      font-size: 0.95rem;
      font-family: var(--primary-medium-font)
    }
    .auth-form {
      
      min-width: 300px;
      padding: 50px 70px 40px 70px;
      display: flex;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      flex-direction: column;
      color: white;
      justify-content: center;
    }
    .auth-form .auth-flex-item {
      margin: 10px 0;
      justify-content: flex-start;
    }
    .auth-form .auth-flex-item .auth-button {
      width: 100%;
      height: 45px;
      font-size: 0.8rem;
      letter-spacing: 1px;
      background-color: white;
      border: none;
      border-radius: 50px;
    }
    .auth-form .auth-flex-item .auth-button:hover {
      cursor: pointer;
      background-color: rgba(77, 88, 106, 0.4);
      color: white;
    }
    .forgot-credit-form-container {
      min-width: 300px;
      padding: 50px 60px 40px 60px;
      display: flex;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      flex-direction: column;
      color: white;
      justify-content: center;
    }
    .forgot-credit-form-container .auth-flex-item {
      margin: 10px 0;
      justify-content: flex-start;
    }
    .forgot-credit-form-container .forgot-credit-form-title {
      width: 100%;
      font-size: 0.8rem;
      text-align: left;
    }
    .forgot-credit-form-container .forgot-credit-form-p {
      width: 100%;
      margin-bottom: 30px;
      text-align: left;
    }
    .forgot-credit-form-container .forgot-credit-form-input {
      width: 100%;
    }
    .forgot-credit-form-container .forgot-credit-form-input input {
      width: 100%;
      border: none;
      min-height: 40px;
      outline: none;
      color: white;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      background: none;
    }
    .forgot-credit-form-container .forgot-credit-form-button-container {
      margin-top: 20px;
    }
    .forgot-credit-form-container .forgot-credit-form-action-container .action-button {
      width: 100%;
      background-color: rgba(0, 0, 0, 0);
      margin-bottom: 0;
      border: none;
      color: white;
      font-family: var(--primary-medium-font)
    }
  }
  .btn-hvr-an:hover {
    transition: background-color 200ms, color 200ms;
  }
  